const config = {
  WS_CAPTIONS_URL: process.env.REACT_APP_WS_CAPTIONS_URL,
  STREAM_PLAYBACK_URL: process.env.REACT_APP_STREAM_PLAYBACK_URL,
  CAPTIONS_TRANSLATIONS_LANGUAGE_CODES: process.env.REACT_APP_CAPTIONS_TRANSLATIONS_LANGUAGE_CODES,
  ENABLE_TRANSLATE: process.env.REACT_APP_ENABLE_TRANSLATE,
  AUDIO_LANGUAGE_CODE: process.env.REACT_APP_AUDIO_LANGUAGE_CODE,
  MAX_OVERLAYS: 4,
  TIME_OVERLAYS: 6000,
  BIG_TYPOGRAPHY_LANGUAGES: ['hi', 'ja', 'ka', 'ko', 'zh', 'zh-TW'],
  RIGHT_ALIGNED_LANGUAGES: ['ar', 'he'],
};

export default config;
